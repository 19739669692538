<template>
  <CampaignManager
    type="CAMPAIGN_MANAGER_TODAY"
    :func-get-opportunities="getOpportunityByEndDate"
    :show-date-picker="false"
    :end-date="endDate"
  ></CampaignManager>
</template>
<script>
import CampaignManager from '@/views/private/modules/thirdParty/campaignManager/CampaignManager.vue';
import { getOpportunityByEndDate } from '@/services/modules/ThirdParty/opportunity';
import { TODAY } from '@/utils/dateTime/today';

export default {
  name: 'CampaignManagerToday',
  components: { CampaignManager },
  data: () => ({
    endDate: TODAY,
  }),
  methods: {
    getOpportunityByEndDate,
  },
};
</script>
